<template>
  <div class="home">
    <span style="color:blue">首页功能开发中。。。</span>
    <div style="margin:40px 0 20px 0">
        <el-form :inline="true">
          <el-form-item label="责任单位">
            <el-input style="width: 180px;" placeholder="责任单位"/>
          </el-form-item>
          <el-form-item label="选择地区">
            <el-select style="width: 180px;" placeholder="选择地区"/>
            <el-select style="width: 180px;margin-left:10px" placeholder="选择街道/乡镇"/>
          </el-form-item>
          <el-form-item label="问题时间">
              <el-date-picker
                type="daterange"
                range-separator="至"
                start-placeholder="开始时间"
                end-placeholder="结束时间"
                :size="size"
              />
          </el-form-item>
          <el-form-item>
            <el-button-group>
              <el-button type="primary" @click="commitFindList()">搜索</el-button>
              <el-button @click="resetSearchParam()">重置</el-button>
            </el-button-group>
          </el-form-item>
       </el-form>
    </div>

    <div v-if="show.showStatistic" style="margin-top:50px">
      <el-row>
        <el-col :span="14">
          <el-row :gutter="35">
            <el-col :span="8">
              <el-card shadow="hover" class="card" style="width:90%">
                <div style="margin:0px 0px 15px 0px ;font-size: 17px;">问题数量</div>
                <div style="font-weight: bold;font-size: 25px;">0</div>
              </el-card>
            </el-col>
            <el-col :span="8">
              <el-card shadow="hover" class="card" style="width:90%">
                <div style="margin:0px 0px 15px 0px ;font-size: 17px;">反馈问题数量</div>
                <div style="font-weight: bold;font-size: 25px;">0</div>
              </el-card>
            </el-col>
            <el-col :span="8">
              <el-card shadow="hover" class="card" style="width:90%">
                <div style="margin:0px 0px 15px 0px;font-size: 17px;">未整改问题数量</div>
                <div style="font-weight: bold;font-size: 25px;">0</div>
              </el-card>
            </el-col>
            <el-col :span="8">
              <el-card shadow="hover" class="card" style="width:90%">
                <div style="margin:0px 0px 15px 0px ;font-size: 17px;">问题点位数量</div>
                <div style="font-weight: bold;font-size: 25px;">0</div>
              </el-card>
            </el-col>
            <el-col :span="8">
              <el-card shadow="hover" class="card" style="width:90%">
                <div style="margin:0px 0px 15px 0px ;font-size: 17px;">督导点位数量</div>
                <div style="font-weight: bold;font-size: 25px;">0</div>
              </el-card>
            </el-col>
            <el-col :span="8">
              <el-card shadow="hover" class="card" style="width:90%">
                <div style="margin:0px 0px 15px 0px ;font-size: 17px;">未整改点位数量</div>
                <div style="font-weight: bold;font-size: 25px;">0</div>
              </el-card>
            </el-col>
          </el-row>

          <el-row :gutter="30" style="margin-top:10px">
            <el-col :span="12">
              <el-card shadow="hover" style="width:93%">
                <div id="chart1" style="width: 100%; height:200px"></div>
              </el-card>
            </el-col>
            <el-col :span="12">
              <el-button-group style="position: absolute;top:10px;right: 55px;z-index: 999" v-model="chartType">
                  <el-button size="mini">整改情况</el-button>
                  <el-button size="mini">问题类型</el-button>
                  <el-button size="mini">反复情况</el-button>
                </el-button-group>
              <el-card shadow="hover" style="width:93%">
                <div id="chart2" style="width: 100%; height:200px"></div>
              </el-card>
            </el-col>
          </el-row>
        </el-col>

        <el-col :span="10">
          <el-card shadow="hover" class="card" style="width:90%">
              <div style="font-size: 17px;">区域分布</div>
              <div id="map" style="width:100%;height:100%">
                    <CiyMap style="width: 100%;" />
              </div>
          </el-card>
        </el-col>
      </el-row>
    </div>

    <div v-else>
      <h1 style="text-align: center; width: 100%;">你好，{{ show.userInfo.nick }}。欢迎访问文明沧州 实地督导系统。</h1>
      <div>
        <el-empty description="您无权限查看统计数据" />
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, reactive, onMounted, onUnmounted } from 'vue'
import CiyMap from '../../components/CityMap.vue'
import math from '../../utils/math'
import api from '../../apis/api'
import * as echarts from 'echarts'
import util from '../../utils/util'

// 权限判定

const show = reactive({
  userInfo: {},
  showStatistic: true,
})

let now = new Date()

onMounted(() => {
  resetChart1()
  resetChart2()
})

const funs = {
  onResizeWindow: () => {
    chart1.resize()
    chart2.resize()
  }
}
let chart1 = null
let chart2 = null
onUnmounted(() => {
  if (show.showStatistic) {
    // 移除监听窗口变化
    window.removeEventListener('resize', funs.onResizeWindow)
  }
})

const resetChart1 = () => { 
  chart1 = echarts.init(document.getElementById('chart1'))
    chart1.setOption({
      title: {
        text: '部门分布',
        left: 'left'
      },
      legend: {
        orient: 'vertical',
        x:'right',
        y:'center'
      },
      series: [
        {
          name: 'Dept',
          type: 'pie',
          radius: '50%',
          data: [
            { value: 1048, name: '部门1' },
            { value: 735, name: '部门2' },
            { value: 580, name: '部门3' },
          ],
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: 'rgba(0, 0, 0, 0.5)'
            }
          }
        }
      ]
      
    })
}

const resetChart2 = () => { 
  chart2 = echarts.init(document.getElementById('chart2'))
    chart2.setOption({
      title: {
        text: '问题分布',
        left: 'left'
      },
      legend: {
        orient: 'vertical',
        x:'right',
        y:'center'
      },
      series: [
        {
          name: 'Dept',
          type: 'pie',
          radius: '50%',
          data: [
            { value: 1048, name: '部门1' },
            { value: 735, name: '部门2' },
            { value: 580, name: '部门3' },
          ],
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: 'rgba(0, 0, 0, 0.5)'
            }
          }
        }
      ]
      
    })
}
</script>

<style lang="less">
.home {
  .card {
    margin-bottom: 30px;
  }
}
</style>