<template>
    <div class="box">
      <div ref="container" style="width: 100%; flex: 1"></div>
    </div>
    <map></map>
  </template>
   
  <script setup>
  import * as echarts from "echarts";
  import { onMounted, onUnmounted, ref } from "vue";
  import mapData from "./map.json";
  const container = ref(null);
  const chartInstance = ref(null);
  const setOption = (options) => {
    chartInstance.value.setOption(options);
  };

  // 获取并设置图表配置项
  const getOptions = () => {
      chartInstance.value.hideLoading();
      echarts.registerMap("ZG", {geoJSON:mapData});
      let option = {
        //提示框组件配置项
        tooltip: {
          trigger: "item", //触发类型
        },
        series: [
          {
            name: "沧州市地图",
            type: "map",
            map: "ZG",
            label: {
            //   show: true,
            },
            itemStyle:{
              areaColor: 'rgb(100,156,255)',
              borderColor: "rgb(100,200,245)",
              shadowBlur:1,
              shadowColor:'rgb(100,200,235)',
              borderWidth: 1.5,
            },
            emphasis:{
                itemStyle:{
                    areaColor: 'rgb(100,200,245)',
                    borderColor: "#fff",
                    shadowOffsetX:5,
                    shadowColor: 'rgb(100,200,245)',
                },
            }
            
          },
        ],
      };
      setOption(option);
  };
   
  onMounted(() => {
    chartInstance.value = echarts.init(container.value);
    chartInstance.value.showLoading();
    getOptions();
    // 添加点击事件监听器
    window.onresize = () => {
      chartInstance.value.resize();
    };
  });
  onUnmounted(() => {
    // 页面销毁的时候，让地图实例也销毁
    if (chartInstance.value) {
      chartInstance.value.dispose();
    }
  });
  </script>
  <style scoped>
  .box {
    display: flex;
    flex-direction: column;
    align-items: center;
    height:465px;
    text-align: right;
  }

  </style>